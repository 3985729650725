<template>
  <div class="user">
    <search-header :isSnShow="false"></search-header>
    <div class="container pt-3 pb-3 p-0">
      <div class="row p-0">
        <div class="col-2">
          <user-left :currentActive="9"></user-left>
        </div>
        <div class="col-10 f-14">
          <div class="row bg-white rounded ml-2">
            <div class="p-3 col-12">
              <b-tabs lazy>
                <b-tab title="网站公告"></b-tab>
                <b-tab title="系统消息"></b-tab>
              </b-tabs>
            </div>
            <div class="w-100 pl-3 pr-3">
              <div class="card border-0">
                <div class="card-body p-0">
                  <div class="table-responsive">
                    <table class="table table-centered border mb-0">
                      <thead class="table-light">
                        <tr>
                          <th class="w-75">消息</th>
                          <th>时间</th>
                          <th style="width: 80px">操作</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="i in 10" :key="i">
                          <td>
                            <h6>茶颜悦色的冰淇淋好吃吗？多少钱一个？</h6>
                            <div class="mt-2 text-gray-777">
                                火把节是白族、彝族、拉祜族等少数民族地区的一个传统节日，在这天人们会吃羊肉、牛肉、烧火把等，部分地区还会放假，那火把节放假通知2021是怎样的？具体放几天？几月几号放假？附最新放假安排！ 一、火把节放假通知2021具体放几天？几月几号放假？ 1.凉山州火把节放假通知2021 根据2020年12月10日，凉山州人民政府办公室发布关于2021年部分节
                            </div>
                          </td>
                          <td>
                            <div class="mt-1">2021-07-31 10:29</div>
                          </td>
                          <td>
                            <div class="mt-1 mb-1 f-16">
                              <a href="javascript:void(0);" class="ml-2">
                                <b-icon icon="trash"></b-icon>
                              </a>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div
                    class="d-flex justify-content-between align-items-center"
                  >
                    <div></div>
                    <div>
                      <b-pagination
                        class="mt-1 mb-1"
                        :total-rows="10"
                        :per-page="1"
                        aria-controls="my-table"
                      ></b-pagination>
                    </div>
                  </div>
                </div>
                <!-- end card-body-->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <map-footer :isMapShow="false"></map-footer>
  </div>
</template>

<script>
export default {
  name: "myask",
  data() {
    return {};
  },
  methods: {},
};
</script>

<style scoped>
@import url("~@/assets/css/user.css");
@import url("~@/assets/css/ask.css");
</style>
